import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Telephone, Map, Envelope } from "react-bootstrap-icons"
import NavDropdown from "react-bootstrap/NavDropdown"
import Guide from "../../files/sample-size-guidelines.pdf"

const footer = (props) => {
  return (
    <div>
      <section className="sub-footer">
        <div id="footer" />
        <Container>
          <Row>
            <Col
              lg="3"
              className="d-flex justify-content-center align-items-center"
            >
              <a href="tel:3038155704" alt="Bona Fides Lab Phone Number">
                <Telephone className="mr-2" style={{ marginBottom: "2px" }} />
                New Clients and Sales: 303.815.5704
              </a>
            </Col>
            <Col
              lg="3"
              className="d-flex justify-content-center align-items-center"
            >
              <a href="tel:3032920221" alt="Bona Fides Lab Phone Number">
                <Telephone className="mr-2" style={{ marginBottom: "2px" }} />
                Main Lab: 303.292.0221
              </a>
            </Col>
            <Col
              lg="3"
              className="d-flex justify-content-center align-items-center"
            >
              <a
                href="https://goo.gl/maps/rtowiauHAhV3Xm8K6"
                alt=" Bona Fides Lab Location"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Map className="mr-2" style={{ marginBottom: "2px" }} />
                4910 Fox Street Unit E, <br />
                Denver, CO 80216
              </a>
            </Col>
            <Col
              lg="3"
              className="d-flex justify-content-center align-items-center"
            >
              {" "}
              <div>
                {" "}
                <a
                  href="mailto:nmathis@bonafideslab.com"
                  alt="Bona Fides Lab Email"
                >
                  <Envelope className="mr-2" style={{ marginBottom: "2px" }} />
                  nmathis@bonafideslab.com
                </a>
                <br />
                <a
                  href="mailto:dmathis@bonafideslab.com"
                  alt="Bona Fides Lab Email"
                >
                  <Envelope className="mr-2" style={{ marginBottom: "2px" }} />
                  dmathis@bonafideslab.com
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-section">
        <Container>
          <Row>
            <Col lg="2">
              <StaticImage
                className="mb-3"
                src="../../images/logo-black.svg"
                width={130}
              />
            </Col>
            <Col lg="3">
              <h5 className="mt-4">QUICK LINKS</h5>
              <Row>
                <Col lg="4" className="col-6">
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/#about">About</Link>
                    </li>
                    <li>
                      <Link to="/#services">Services</Link>
                    </li>
                  </ul>
                </Col>
                <Col lg="8" className="col-6">
                  <ul className="list-unstyled">
                    <li className="mb-0">
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title="Resources"
                        menuVariant="dark"
                        style={{
                          padding: "0",
                          left: "0",
                          top: "0",
                          height: "30px",
                        }}
                      >
                        <NavDropdown.Item>
                          <Link to="/medbulletin" className="text-dark">
                            Med Bulletin
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link
                            to="/sample-submission-guidelines"
                            className="text-dark"
                          >
                            {" "}
                            Sample Submission Guidelines
                          </Link>
                        </NavDropdown.Item>{" "}
                        <NavDropdown.Item>
                          <Link
                            to="/sample-size-guidelines"
                            className="text-dark"
                          >
                            {" "}
                            Sample Size Guidelines
                          </Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </li>
                    <li>
                      <Link to="/#contact">Contact</Link>
                    </li>

                    <li>
                      <a
                        href="https://orders.confidentcannabis.com/#!/login?lab=bonafides"
                        alt="Confident Cannabis Login"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Confident Cannabis Login
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg="3">
              <h5 className="mt-4">Downloads & Data Access</h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://res.cloudinary.com/dwzjvi83z/raw/upload/v1619752490/bonafides/bflab_coc_jr6qng.xls"
                    alt="Chain of Custody"
                    download
                  >
                    Chain of Custody
                  </a>
                </li>
                <li>
                  <a
                    // href="https://data.bonafideslab.com/"
                    href="http://data.bonafideslab.com"
                    alt="Data Access"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Data Access
                  </a>
                </li>
                <li>
                  <a
                    href={Guide}
                    alt="Lab Testing Sample Size Guide"
                    target="_blank"
                  >
                    Lab Testing Sample Size Guide
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg="4">
              <h5 className="mt-4">Certifications</h5>
              <Row>
                <Col lg="7">
                  <ul className="list-unstyled">
                    <li>Denver and Colorado licensed testing facility</li>
                    <li>MED Recreational License #405R-00010</li>
                    <li>MED Medical License #405-00011</li>
                    <li>
                      Accredited to ISO/IEC 17025:2017 Accreditation No. 82006
                    </li>
                  </ul>
                </Col>
                <Col lg="4">
                  <StaticImage src="../../images/pjla.svg" width={130} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="copyright">
        <div className="container">
          <span>
            Bona Fides Laboratory, Inc. © {new Date().getFullYear()} All rights
            reserved. Designed and powered by {``}
            <a
              href="https://webriq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  )
}

export default footer
