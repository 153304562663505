import React, { useState } from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import { Container, Row, Col } from "react-bootstrap"
import { stickyWrapper, isSticky, header } from "./header.module.css"
import { Person } from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"
import HorizontalMenu from "../sidebar/horizontalMenu"
import WebriqForm from "@webriq/gatsby-webriq-form"
import { Envelope } from "react-bootstrap-icons"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

export default function AboutSection() {
  const [modal, setModal] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // Toggle for Modal
  const toggle = () => setModal(!modal)
  return (
    <header className="relative">
      <Sticky
        className={`${stickyWrapper}`}
        stickyClassName={`${isSticky}`}
        stickyStyle={{ transform: "unset", zIndex: "99999" }}
      >
        <div className={`header ${header}`}>
          <section className="banner-nav p-3">
            <Container>
              <Row>
                <Col
                  lg="10"
                  className="justify-content-center align-items-center mx-auto text-white text-center"
                >
                  {/* <Info className="mr-2" style={{ marginBottom: "2px" }} /> */}
                  <p className="m-0" style={{ fontSize: "24px" }}>
                    Sorry for the inconvenience, our website is under
                    construction.
                  </p>
                  {/* <p style={{ fontSize: "20px" }}>
                    please contact your project manager if you're looking for
                    data
                  </p> */}
                </Col>
              </Row>
            </Container>
          </section>
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-2">
                <div className="header-logo">
                  <Link to="/">
                    <StaticImage src="../../images/logo-black.svg" width={80} />
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="d-lg-block d-none">
                  <div className="d-lg-block d-none justify-content-center align-items-center">
                    <HorizontalMenu />
                  </div>
                </div>
              </div>
              <div className="col-2 d-lg-block d-none">
                <div className="text-right">
                  <a
                    className="login-btn btn mr-3"
                    // href="https://data.bonafideslab.com/"
                    href="http://data.bonafideslab.com"
                    title="Confident Cannabis Login"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Person className="mr-2" style={{ marginBottom: "2px" }} />{" "}
                    Login
                  </a>
                </div>
                <div
                  style={{ position: "absolute", top: "0", right: "-211px" }}
                >
                  <Button variant="btn" onClick={handleShow}>
                    Request a Courier
                  </Button>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Request a Courier</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <WebriqForm
                      method="POST"
                      data-form-id="cd83453b-dcf8-4526-ac79-d9c6b4a5b305"
                      name="Sample Submission Form"
                      className="form-contacts p-5 bg-gray-50"
                      data-thankyou-url="/thank-you"
                    >
                      <div className="form-row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              name="Name"
                              type="text"
                              id="fullName"
                              required=""
                              placeholder="Full Name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              name="Email Address"
                              type="email"
                              id="homeEmail"
                              required=""
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control h-auto"
                          name="Message"
                          id="message"
                          required=""
                          rows="4"
                          cols="50"
                          placeholder="Message"
                        />
                      </div>
                      <div className="form-group">
                        <div className="webriq-recaptcha" />
                      </div>
                      <div className="form-group mb-0">
                        <button className="btn btn-alt w-100" type="submit">
                          <Envelope
                            className="mr-2"
                            style={{ marginBottom: "2px" }}
                          />
                          Send Message
                        </button>
                      </div>
                    </WebriqForm>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
              <div className="col d-lg-none d-block">
                <div className="text-right">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}
