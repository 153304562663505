import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import NavDropdown from "react-bootstrap/NavDropdown"

const horizontalMenu = () => {
  return (
    <div className="horizontal-nav">
      <Nav className="nav-links d-flex justify-content-between align-items-center">
        <Nav.Item>
          <Link to="/">Home</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#about">About</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#services">Services</Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Link to="/#footer">Certifications</Link>
        </Nav.Item> */}
        <Nav.Item>
          <NavDropdown
            id="nav-dropdown-dark-example"
            className="py-0 my-0"
            title="Resources"
            menuVariant="dark"
          >
            <NavDropdown.Item>
              <Link to="/medbulletin" className="text-dark">
                Med Bulletin
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/sample-submission-guidelines" className="text-dark">
                {" "}
                Sample Submission Guidelines
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/sample-size-guidelines" className="text-dark">
                {" "}
                Sample Size Guidelines
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav.Item>
        <Nav.Item>
          <Link to="#contact">Contact</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#downloads">Download & Data Access</Link>
        </Nav.Item>
        {/* <Nav.Item>
          <a
            href="https://orders.confidentcannabis.com/#!/login?lab=bonafides"
            title="Confident Cannabis Login"
            target="_blank"
            rel="noreferrer noopener"
          >
            Confident Cannabis Login
          </a>
        </Nav.Item> */}
      </Nav>
    </div>
  )
}

export default horizontalMenu
